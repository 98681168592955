import { useState, useEffect } from "react";

import { CognitoUserPool } from "amazon-cognito-identity-js";

import { CognitoJwtVerifier } from "aws-jwt-verify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetPoolData } from "../../clientRequest/env-variable";

const useGetToken = () => {
  const [token, setToken] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const getPoolData = useGetPoolData();

  useEffect(() => {
    const checkSessionExpiration = async () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(async (err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const jwtToken = session.getIdToken().jwtToken;
              const user = cognitoUser.username.split("@")[0];
              const capitalized = user.charAt(0).toUpperCase() + user.slice(1);
              setCurrentUser(capitalized);
              const verifier = CognitoJwtVerifier.create({
                userPoolId: getPoolData.UserPoolId,
                tokenUse: "id",
                clientId: getPoolData.ClientId,
              });
              const payload = await verifier.verify(jwtToken);
              if (payload) {
                setToken(jwtToken);
              } else {
                cognitoUser.signOut();
                naviagate("/");
              }
            } else {
              cognitoUser.signOut();
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);
  return {
    token,
    currentUser,
  };
};

export default useGetToken;
