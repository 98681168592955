import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions,
  CardBody,
  StyledCardWithoutButtonContainer,
  Flex,
} from "../index";
import { useTranslation } from "react-i18next";

export const RoutableCard = ({
  image,
  imageTitle,
  header,
  body,
  url,
  linkTitle,
}) => {
  return (
    <Card>
      <CardMedia src={image} alt={imageTitle} />
      <CardContent>
        <CardHeader title={header}>{header}</CardHeader>
        <CardBody title={body}>{body}</CardBody>
      </CardContent>
      <hr />
      <div style={{ padding: "0 1em 1em" }}>
        <CardActions to={url}>{linkTitle} </CardActions>
      </div>
    </Card>
  );
};

export const CardWithoutButton = ({ header, title, amount, date }) => {
  const { t } = useTranslation();

  return (
    <StyledCardWithoutButtonContainer>
      <p
        className="header"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
        }}
        title={header}
      >
        {header}
      </p>
      <Flex column style={{ gap: "1.5em" }}>
        <p className="amount">{amount}</p>
        <p className="title">{title}</p>
      </Flex>
      <p className="date">
        {t("dashboard.home.cards.card_without_last_updated")}: {date}
      </p>
    </StyledCardWithoutButtonContainer>
  );
};
